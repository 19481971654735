<template>
  <blank-layout>
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <logo class="mx-auto max-h-40 w-auto" />
      <h2
        class="mt-6 text-center text-3xl leading-9 text-gray-900"
      >
        Sign in to your account
      </h2>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 border border-gray-200">
        <a-alert
          v-if="error"
          type="error"
          title="Authentication Failed"
        >
          <div v-html="error" />
        </a-alert>
        <a-form @submit="onSubmit">
          <div>
            <a-label
              for="email"
              :error="hasEmailErrors"
            >
              Email Address
            </a-label>
            <div class="mt-1 rounded-md shadow-sm">
              <a-input
                id="email"
                v-model="email"
                type="email"
                :readonly="loading"
                :error="hasEmailErrors"
                required
              />
              <div v-if="hasEmailErrors">
                <a-help error>
                  A valid Email Address is required.
                </a-help>
              </div>
            </div>
          </div>

          <div class="mt-6">
            <a-label
              for="password"
              :error="hasPasswordErrors"
            >
              Password
            </a-label>
            <div class="mt-1 rounded-md shadow-sm">
              <a-input
                id="password"
                v-model="password"
                type="password"
                :readonly="loading"
                required
                :error="hasPasswordErrors"
              />
              <div v-if="hasPasswordErrors">
                <a-help error>
                  A valid Password is required.
                </a-help>
              </div>
            </div>
          </div>

          <div class="mt-12">
            <span class="block w-full rounded-md shadow-sm">
              <a-button
                :loading="loading"
                class="w-full"
                @click="onSubmit"
              >Sign In</a-button>
            </span>
          </div>

          <magic-link-button
            :conference="current"
            :email="email"
            @error="onMagicLinkError"
            @completed="onMagicLinkCompleted"
          />

          <div class="mt-6 flex justify-center">
            <div class="text-sm leading-5">
              <router-link
                :to="{name: 'reset.password', params: {conference: current}}"
                class="font-medium text-gray-400 hover:text-gray-700 focus:outline-none focus:underline transition ease-in-out duration-150"
              >
                Forgot your password?
              </router-link>
            </div>
          </div>
        </a-form>
      </div>
    </div>
  </blank-layout>
</template>

<script>
import { join, get, isEmpty } from '@/utils/lodash';
import BlankLayout from '@/layouts/blank.vue';
import { mapActions, mapGetters } from 'vuex';
import { VuelidateMixin } from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';
import Logo from '@/components/layout/header/logo.vue';
import MagicLinkButton from '@/components/auth/magic/button.vue';
export default {
  components: {
    Logo,
    BlankLayout,
    MagicLinkButton
  },
  mixins: [VuelidateMixin],
  data: () => ({
    email: '',
    password: '',
    loading: false,
    error: null,
    submitted: false
  }),
  validations: {
    email: { required, email },
    password: { required }
  },
  computed: {
    ...mapGetters('auth', ['hasToken']),
    ...mapGetters('conferences', ['current']),
    hasEmailErrors() {
      return !isEmpty(this.$v.email.$errors);
    },
    hasPasswordErrors() {
      return !isEmpty(this.$v.password.$errors);
    }
  },
  watch: {
    current() {
      this.onVerifyGuest();
    },
    hasToken() {
      this.onVerifyGuest();
    },
    ['$route.query']: {
      immediate: true,
      handler: 'onRouteQueryChange'
    }
  },
  methods: {
    ...mapActions('auth', ['login']),
    onRouteQueryChange() {
      if (!this.email && get(this.$route.query, 'email', null)) {
        this.email = get(this.$route.query, 'email');
      }
      if (!this.password && get(this.$route.query, 'password', null)) {
        this.password = get(this.$route.query, 'password');
      }

      // If we have both then submit
      if (!this.submitted && this.email && this.password) {
        this.onSubmit();
      }
    },
    onVerifyGuest() {
      if (this.current && this.hasToken) {
        this.$router.replace({
          name: 'dashboard',
          params: { conference: this.current }
        });
      }
    },
    async onSubmit() {
      this.$v.$reset();
      this.$v.$touch();

      if (this.$v.$error) {
        return;
      }

      this.loading = true;
      this.error = null;
      this.submitted = false;

      try {
        await this.login({
          email: this.email,
          password: this.password
        });
        this.submitted = true;

        this.$router.push({
          name: 'dashboard',
          params: { conference: this.current }
        });
      } catch ({
        response: {
          data: { errors }
        }
      }) {
        this.error = join(errors, '<br />');
      } finally {
        this.loading = false;
      }
    },
    onMagicLinkError(errors) {
      this.error = join(errors, '<br />');
    },
    onMagicLinkCompleted() {
      this.error = null;
    }
  }
};
</script>