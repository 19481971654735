<template>
  <div
    class="min-h-full bg-gray-50 flex flex-col justify-center px-2 py-12 sm:px-6 lg:px-8"
  >
    <div v-if="loaded">
      <slot />
    </div>
    <div v-else>
      <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md text-center">
        <a-icon
          name="circle-notch"
          type="fad"
          size="5x"
          class="text-gray-300"
          spin
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters('conferences', ['loaded'])
  }
};
</script>