<template>
  <div class="mt-2">
    <div v-if="completed">
      <a-alert type="success">
        Magic-Link sent to <i>{{ email }}</i>.
      </a-alert>
    </div>
    <div
      v-else
      v-tooltip.bottom="tooltip"
      class="block w-full rounded-md shadow-sm"
    >
      <a-button
        :loading="loading"
        class="w-full"
        variant="secondary"
        size="sm"
        :disabled="!email"
        @click="onMagicLink"
      >
        Email Magic Link
      </a-button>
    </div>
  </div>
</template>

<script>
import { isEmpty } from "@/utils/lodash";
import { mapActions } from "vuex";
export default {
  props: {
    email: {
      type: String,
      required: true
    },
    conference: {
      type: String,
      required: true
    }
  },
  emits: ['error', 'completed'],
  data: () => ({
    loading: false,
    completed: false
  }),
  computed: {
    tooltip() {
      let tip = 'Magic Links allow to sign-in immediately without the need to enter a password.';
      if(isEmpty(this.email)) {
        tip += ' Enter an email address above to be able to send the magic link.';
      }
      return tip;
    }
  },
  methods: {
    ...mapActions('magic', {emailMagicLink: 'email'}),
    async onMagicLink() {
      this.loading = true;
      try {
        await this.emailMagicLink({conference: this.conference, email: this.email});
        this.completed = true;
        this.$emit('completed')
      } catch({
        response: {
          data: { errors }
        }
      }) {
        this.$emit('error', errors);
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>